import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../stores/rootStore';

const Header: React.FC = observer(() => {
    const rootStore = useContext(RootStoreContext);
    const authStore = rootStore.authStore;

    return (
        <header className="sticky top-0 bg-gray-800">
            <nav className="flex justify-end">
                <ul>
                    {/* <li>
                        <a href="/">Home</a>
                    </li>
                    <li>
                        <a href="/about">About</a>
                    </li> */}
                    <li className=''>
                        {authStore.isAuthenticated ? (
                            <>
                                <span className='text-gray-200'>{authStore.user?.toString()}</span>
                                <button onClick={authStore.logout} className='px-3'><span className='text-gray-200'>Logout</span></button>
                            </>
                        ) : (
                            <a onClick={authStore.login}>Login</a>
                        )}
                    </li>
                </ul>
            </nav>
        </header>
    );
});

export default (Header);