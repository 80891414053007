import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../stores/rootStore';
import AuthStore from '../../stores/authStore';
import Header from './header';

type AuthWrapperProps = {
    children: React.ReactNode;
};

const AuthWrapper: React.FC<AuthWrapperProps> = (({ children }) => {
    const rootStore = useContext(RootStoreContext);
    const authStore: AuthStore = rootStore.authStore;

    return (
        <>
            <Header />
            {authStore.isAuthenticated ? (
                <>
                    {authStore.isAdmin ? (
                        children
                    ) : (
                        <div className="flex items-center justify-center h-screen" style={{ height: 'calc(100vh - 4rem)' }}>
                            <p className="text-center">You are not authorized to see this page....</p>
                        </div>
                    )
                    }
                </>
            ) : (
                <div className="flex items-center justify-center h-screen" style={{ height: 'calc(100vh - 4rem)' }}>
                    <p className="text-center">Waiting for authentication....</p>
                </div>
            )}

        </>

    );
});

export default observer(AuthWrapper);