import { observable, action, runInAction, makeAutoObservable } from 'mobx';
import { act } from 'react';
import { RootStore } from './rootStore';
import AuthStore from './authStore';

export default class ImageStore {

    authStore: AuthStore;
    constructor(rootStore: RootStore) {
        this.authStore = rootStore.authStore;
        makeAutoObservable(this);
    }

    
    get_api_url(): string {
        return process.env.REACT_APP_BACKEND_URL || 'https://bapi.zohius.com';
    }


    @observable uploadComponentVisible: boolean = false;
    @observable imageReadyForUpload: boolean = false;
    @observable imageAdded: File | null = null;
    @observable imageAddedUrl: string | null = null;
    @observable uploading: boolean = false;
    @observable uploadedMessage: string | null = null;
    @observable showSpinnnerForUpload: boolean = false;

    //@observable imagesList: string[] = [];
    @observable imagesPathList: string[] = [];

    @action
    showUploadComponent(): void {
        this.uploadComponentVisible = true;
    }

    // @action
    // removeImage(): void {
    //     this.imageAdded = null;
    //     this.imageReadyForUpload = false;
    //     this.showSpinnnerForUpload = false;
    //     this.uploadedMessage = null;
    //     this.showUploadComponent = false;
    // }

    @action
    uploadImage(): void {

        this.showSpinnnerForUpload = true;

        let jwt = this.authStore.getToken();

        const formData = new FormData();
        if (this.imageAdded) {
            formData.append('file', this.imageAdded as Blob);
        }

        const path = `${ this.get_api_url()}/Images`;
        fetch(path, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + jwt,
            },
            body: formData
        })
            .then(response => runInAction(() => {
                this.onFinished(response.statusText);
                this.loadImagesList();
            }))
            .catch(error => {
                runInAction(() => {
                    this.onFinished('Error: ' + error.message);
                });
            });

    }

    async onFinished(message: string): Promise<void> {
        this.imageAdded = null;
        this.uploading = false;
        this.uploadedMessage = message;
        this.imageReadyForUpload = false;
        this.showSpinnnerForUpload = false;
        this.uploadComponentVisible = false;
        this.loadImagesList();
    }

    @action
    addImages(file: File): void {
        this.showUploadComponent();
        this.imageAdded = file;
        this.imageReadyForUpload = true;
        this.imageAddedUrl = URL.createObjectURL(file);
    }

    @action
    cancelUpload(): void {
        this.onFinished('Cancelled');
    }

    @action loadImagesList(): void {
        let jwt = this.authStore.getToken();
        const path = `${this.get_api_url()}/Images`;
        fetch(path, {
            headers: {
                'Authorization': 'Bearer ' + jwt,
            },
        })
            .then(response => response.json())
            .then(data => runInAction(() => {
                this.imagesPathList = data.map((imageName: string) => `${this.get_api_url()}/Images/${imageName}`);
            }))
            .catch(error => {
                console.error('Error:', error);
            });

    }

    @action deleteImage(imagePathName: string): void {
        let jwt = this.authStore.getToken();
        //const path = `${this.get_api_url()}/Images/${imagePathName}`;
        fetch(imagePathName, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + jwt,
            },
        })
            .then(response => runInAction(() => {
                this.loadImagesList();
            }))
            .catch(error => {
                console.error('Error:', error);
            });

    }


}

