import React from 'react';
import './App.css';
import { Outlet, useLocation } from 'react-router-dom';
import Home from './pages/home';
import AuthWrapper from './layout/auth_wrapper';


function App() {
  const location = useLocation();

  return (
    <div className="App">
      <AuthWrapper>
        {location.pathname === '/' ? <Home /> : (
          <Outlet />
        )}
      </AuthWrapper>
    </div>
  );
}

export default App;
