//root store for all stores
import { createContext } from "react";
import { makeAutoObservable, observable, runInAction } from "mobx";
import AuthStore from './authStore';
import ImageStore from "./imageStore";

export class RootStore {
    authStore: AuthStore;
    imageStore: ImageStore;
    constructor() {
        this.authStore = new AuthStore();
        this.imageStore = new ImageStore(this);
        makeAutoObservable(this);
    }
}

export const RootStoreContext = createContext(new RootStore());