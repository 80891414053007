import { observable, action, makeObservable, runInAction, makeAutoObservable } from 'mobx';
import Keycloak, { KeycloakInitOptions, KeycloakTokenParsed } from 'keycloak-js';

//https://medium.hexadefence.com/securing-a-react-app-using-keycloak-ac0ee5dd4bfc
const keycloakInstance = new Keycloak(
    {
        "realm": "zohius",
        "url": "https://id.zohius.com/",
        "clientId": "control"
    });


//authentication store
export default class AuthStore {
    isAuthenticated: boolean = false;
    user: string | null = null;
    isAdmin: boolean = false;


    setUser = async (tokenParsed: KeycloakTokenParsed | undefined) => {
        this.user = tokenParsed?.preferred_username;
        this.isAuthenticated = true;
    }

    constructor() {
        this.login();

        makeAutoObservable(this);
    }

    getToken()
    {
        if (this.isAuthenticated) {
            return keycloakInstance.token;
        } else {
            return null;
        }
    }

    @action
    async login() {
        if (!keycloakInstance.authenticated){
            keycloakInstance
            .init({ onLoad: "login-required"})  // check-sso
            .then(() => {
                runInAction(() => { 
                    this.setUser(keycloakInstance.tokenParsed);
                    this.isAdmin = keycloakInstance.hasRealmRole("admin");
                })
            })
            .catch((e) => {
                console.dir(e);
                console.log(`keycloak init exception: ${e}`);
            });
        }
        
    }

    @action
    async logout() {
        try {
            await keycloakInstance.logout();
            this.isAuthenticated = false;
            this.user = null;
        } catch (error) {
            console.error('Failed to logout', error);
        }
    }
}