import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../stores/rootStore';
import ImageStore from '../stores/imageStore';


const ImagesList: React.FC = observer(() => {
    const rootStore = useContext(RootStoreContext);
    const imageStore: ImageStore = rootStore.imageStore;

    useEffect(() => {
        imageStore.loadImagesList();
    }, []);

    const hadleDeleteClick = (imagePathName: string) => {
        imageStore.deleteImage(imagePathName);
    }

    return (
        <>
            <div className="flex flex-col items-center">
                <h1 className="text-2xl font-bold">Images List</h1>
                {imageStore.imagesPathList.map((imagePathName, index) => {
                    return (
                        <div key={index} className="flex flex-col items-center relative ">
                            <img src={imagePathName} className="object-contain w-1/2 h-1/2 my-1" />
                            <button onClick={() => hadleDeleteClick(imagePathName)} className="absolute top-0 right-0 mt-2 mr-2 text-red-500">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path d="M19 6l-2 14H7L5 6h14z"></path>
                                    <path d="M10 11v6"></path>
                                    <path d="M14 11v6"></path>
                                    <path d="M9 6V4a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2"></path>
                                </svg>
                            </button>
                        </div>
                    )
                })}
            </div>
        </>
    );

});

export default ImagesList;