import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";
import App from '../components/App';


export const routes: RouteObject[] = [
    {
        path: '/',
        element: <App/>,
        // children: [
        //     { path: 'upload', element: <Upload /> }
        // ]
    }
    // Add more routes here if needed
];

export const router = createBrowserRouter(routes);