import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import { RootStoreContext } from "../../stores/rootStore";
import Upload from "../upload";
import ImagesList from "../imagesList";
import ImageStore from "../../stores/imageStore";

const Home = () => {
  const rootStore = useContext(RootStoreContext);
  const imageStore: ImageStore = rootStore.imageStore;

  const handleUploadClick = () => {
    imageStore.showUploadComponent();
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    imageStore.addImages(event.target.files![0]);
  };

  return (
    <>
      {imageStore.uploadComponentVisible ?
        <>
          <Upload />
        </>
        :
        <div >
          <div className="m-4">
            <label htmlFor="fileUpload" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer">
              Upload image
            </label>
            <input id="fileUpload" className="hidden" type="file" accept="image/*" onChange={handleFileChange} />
          </div>
          <hr /> {/* Added horizontal line */}
          <ImagesList />
        </div>
      }


    </>
  );
};

export default observer(Home);