import React, { useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { RootStoreContext } from '../stores/rootStore';
import ImageStore from '../stores/imageStore';
import { observer } from 'mobx-react-lite';
// import { UploadedImage } from './UploadedImage';


const Upload: React.FC = observer(() => {
    const rootStore = useContext(RootStoreContext);
    const imageStore: ImageStore = rootStore.imageStore;

    const handleCancelUpload = () => {
        imageStore.cancelUpload();
    }

    const handleUploadClick = () => {
        imageStore.uploadImage();
    }

    return (
        <div className="h-full">
            <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                {imageStore.showSpinnnerForUpload ?
                    <div className="spinner"></div>
                    :
                    <>

                        <div className="flex flex-col items-center mt-4 max-w-full max-h-full ">
                            <button onClick={handleCancelUpload} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded">
                                Clear
                            </button>
                            <div className=" md:flex">
                                <div className="w-full md:w-1/2 p-1">
                                    {imageStore.imageAddedUrl &&
                                        <img src={imageStore.imageAddedUrl} alt="Uploaded" className="object-contain w-auto h-auto" />
                                    }
                                </div>
                                <div className="w-full md:w-1/2 p-1">
                                    <div className="flex items-center justify-center h-full">
                                        {imageStore.showSpinnnerForUpload ?
                                            <div className="spinner"></div>
                                            :
                                            <button onClick={handleUploadClick} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4">
                                                Upload
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
})

export default Upload;
